import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_AgTextFieldNew = _resolveComponent("AgTextFieldNew")!
  const _component_AgHeading = _resolveComponent("AgHeading")!
  const _component_AgRadioItem = _resolveComponent("AgRadioItem")!
  const _component_AgRadio = _resolveComponent("AgRadio")!
  const _component_AgDiv = _resolveComponent("AgDiv")!
  const _component_AgColumn = _resolveComponent("AgColumn")!
  const _component_AgRow = _resolveComponent("AgRow")!
  const _component_AGButton = _resolveComponent("AGButton")!
  const _component_AgFareCalculator = _resolveComponent("AgFareCalculator")!
  const _component_AgFilterDropdown = _resolveComponent("AgFilterDropdown")!

  return (_openBlock(), _createBlock(_component_AgDiv, {
    "test-id": "",
    class: "d-flex justify-content-end margin_bottom_10 row-column-container"
  }, {
    default: _withCtx(() => [
      (_ctx.isTypeReturn)
        ? (_openBlock(), _createBlock(_component_v_switch, {
            key: 0,
            "test-id": "",
            "hide-details": "",
            class: "pairview-toggle",
            color: "primary",
            inset: "",
            value: true,
            modelValue: _ctx.pairViewModal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pairViewModal) = $event)),
            label: _ctx.renderLabelForPairView,
            style: {"min-width":"50%"}
          }, null, 8, ["modelValue", "label"]))
        : _createCommentVNode("", true),
      _createVNode(_component_AgFilterDropdown, {
        "test-id": "",
        class: "fare_calculator_main",
        label: "Fare Calculator",
        icon: "calculator",
        onClickCapture: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleOpenFilter()))
      }, {
        Items: _withCtx(() => [
          _createVNode(_component_AgFareCalculator, { "test-id": "" }, {
            default: _withCtx(() => [
              _createVNode(_component_AgTextFieldNew, {
                label: _ctx.renderLabel(),
                "test-id": "",
                type: "number",
                variant: "outlined",
                modelValue: _ctx.selectedAmount,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedAmount) = $event)),
                sign: _ctx.renderSign(),
                style: {"align-items":"center"},
                error: _ctx.fieldError
              }, null, 8, ["label", "modelValue", "sign", "error"]),
              _createVNode(_component_AgRow, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgColumn, {
                    xs: "6",
                    sm: "6",
                    md: "6",
                    "test-id": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgDiv, {
                        "test-id": "",
                        class: "fare_calc_box"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AgHeading, {
                            title: "Select Amount Type",
                            variant: "label",
                            "test-id": ""
                          }),
                          _createVNode(_component_AgRadio, {
                            "test-id": "",
                            modelValue: _ctx.selectedAmountType,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedAmountType) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AgRadioItem, {
                                "test-id": "",
                                name: "amount_type",
                                value: "fixed",
                                label: "Fixed Amount"
                              }),
                              _createVNode(_component_AgRadioItem, {
                                "test-id": "",
                                name: "amount_type",
                                value: "percentage",
                                label: "Percentage Amount"
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AgColumn, {
                    xs: "6",
                    sm: "6",
                    md: "6",
                    "test-id": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AgDiv, {
                        "test-id": "",
                        class: "fare_calc_box"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AgHeading, {
                            title: "Select Fare Type",
                            variant: "label",
                            "test-id": ""
                          }),
                          _createVNode(_component_AgRadio, {
                            "test-id": "",
                            modelValue: _ctx.selectedFareType,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedFareType) = $event))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AgRadioItem, {
                                "test-id": "",
                                name: "fare_type",
                                value: "gross",
                                label: "Gross Fare"
                              }),
                              _createVNode(_component_AgRadioItem, {
                                "test-id": "",
                                name: "fare_type",
                                value: "base",
                                label: "Base Fare"
                              })
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_AgRow, { "test-id": "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_AgColumn, {
                    "test-id": "",
                    xs: "6",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AGButton, {
                        class: "full-width red_color",
                        "test-id": "",
                        onClick: _ctx.handleClearFilter,
                        variant: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("CLEAR")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_AgColumn, {
                    "test-id": "",
                    xs: "6",
                    sm: "6",
                    md: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AGButton, {
                        class: "full-width",
                        "test-id": "",
                        variant: "primary",
                        onClick: _ctx.handleApplyFilter
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("APPLY")
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}